import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../helpers/axios.helper';
import { get } from '../../helpers/axios.helper';
import { remove } from '../../helpers/axios.helper';


const getAllAlerts = createAsyncThunk('getAllAlerts', (data: any) => {
    try {
      return get(`alert/allAlert`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const createAlert = createAsyncThunk('createAlert', (data: any) => {
    try {
      return post(`alert/createAlert/${data.typeAlertId},${data.userId}`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getAllAlertTypes = createAsyncThunk('getAllAlertTypes', (data: any) => {
    try {
      return get(`type-alert/allTypeAlert`);
    } catch (error) {
      console.error('Error:', error);
    }
  });
//type-alert/deleteTypeAlert/7444
  const addAlertType = createAsyncThunk('addAlertType', (data: any) => {
    try {
      return post(`type-alert/createTypeAlert`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });
  const deleteAlertType = createAsyncThunk('deleteAlertType', (data: any) => {
    try {
      return remove(`type-alert/deleteTypeAlert/${data?._id}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });


const alertsThunk = {
    getAllAlerts,
    addAlertType,
    getAllAlertTypes,
    createAlert,
    deleteAlertType
  };
export default alertsThunk;