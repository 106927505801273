import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "../../layout/layout";
import Dashboard from "../pages/Dashboard";
import Alerts from "../pages/Alerts";
import Settings from "../pages/Settings";
import "../../layout/layout.css";
import LoginPage from "../pages/auth/login/page";
import Sample from "../pages/auth/login/Sample";
import Areas from "../pages/Areas";
import Agents from "../pages/Agents";
import ErrorBoundary from "../components/ErrorBoundary";
import ProtectedRoute from "../components/ProtectedRoute";

const AppRoutes: React.FC = () => {
  return (
    <Router>
    <Routes>
      <Route path="login" element={<ErrorBoundary><LoginPage /></ErrorBoundary>} />
      
      {/* Protect these routes with token authentication */}
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          </ErrorBoundary>
        }
      >
        <Route index element={<Navigate to="/dashboard" />} />
        <Route
          path="dashboard"
          element={
            <ErrorBoundary>
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
        <Route
          path="areas"
          element={
            <ErrorBoundary>
              <ProtectedRoute>
                <Areas />
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
        <Route
          path="agents"
          element={
            <ErrorBoundary>
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
        <Route
          path="alerts"
          element={
            <ErrorBoundary>
              <ProtectedRoute>
                <Alerts />
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
        <Route
          path="settings"
          element={
            <ErrorBoundary>
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  </Router>
  );
};

export default AppRoutes;
