import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import areasThunk from '../thunks/areas';  

interface AreasState {
    areasList: any;
    loading: boolean;
    error: any;
}
const initialState: AreasState = {
    areasList: [],
    loading: false,
    error: false,
};
const areasSlice = createSlice({
    name: 'areas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(areasThunk.getAllRegions.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(areasThunk.getAllRegions.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.areasList = action.payload.data.regionList; 
        })
        .addCase(areasThunk.getAllRegions.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch areas list';
        });
    }
  });
  
  export const selectAreasList = (state: any) => state.areas.areasList;
  export const areasReducer = areasSlice.reducer;