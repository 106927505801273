import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";

// Define the context type
interface AppContextType {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  theme: string;
  toggleTheme: () => void;
}

// Create the context with default values
const AppContext = createContext<AppContextType | undefined>(undefined);

// Provide the context to the app
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false); // sidebar initially closed
  const [theme, setTheme] = useState<string>("light"); // default theme

  // Check for saved theme in localStorage or system preference
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      document.documentElement.classList.add(savedTheme);
    } else {
      // If no saved theme, use system preference
      const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
      const initialTheme = prefersDark ? "dark" : "light";
      setTheme(initialTheme);
      document.documentElement.classList.add(initialTheme);
    }
  }, []);

  // Toggle sidebar
  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  // Toggle theme between 'light' and 'dark'
  const toggleTheme = () => {
    setTheme((prev) => {
      const newTheme = prev === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);

      // Remove the previous theme class from <html>
      document.documentElement.classList.remove(prev);

      // Add the new theme class to <html>
      document.documentElement.classList.add(newTheme);

      return newTheme;
    });
  };

  return (
    <AppContext.Provider value={{ isSidebarOpen, toggleSidebar, theme, toggleTheme }}>
      <div className={theme}>{children}</div> {/* Apply theme class */}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within AppProvider");
  }
  return context;
};
