import React from 'react';
import logo from './logo.svg';
import './App.css';
import LoginPage from './app/pages/auth/login/page';
import Main from './app/app';

const App: React.FC = () => {
    return (
        <div>
            {/* <h1>Welcome to PrimeReact with TypeScript!</h1>
            <Button label="Click Me" icon="pi pi-check" /> */}
            <Main/>
        </div>
    );
};

export default App;
