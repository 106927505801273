import React from 'react';
import { Outlet } from 'react-router-dom';

const Content: React.FC = () => {
    return (
        <main className="main-content w-full px-[var(--margin-x)] pb-8">
            <Outlet />
        </main>
    );
};

export default Content;
