import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../helpers/axios.helper';
import { get } from '../../helpers/axios.helper';
import { remove } from '../../helpers/axios.helper';
import { put } from '../../helpers/axios.helper';


const getAllUsers = createAsyncThunk('getAllUsers', (data: any) => {
    try {
      return get(`/users/allUsers`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const updateUser = createAsyncThunk('updateUser', (data: any) => {
    try {
      return put(`users/updateUser/${data.userId}`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const activateUser = createAsyncThunk('activateUser', (data: any) => {
    try {
      return put(`users/activateUser/${data.userId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  }); 

  const deactivateUser = createAsyncThunk('deactivateUser', (data: any) => {
    try {
      return put(`users/desactivate/${data.userId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  }); 

  const deleteUser = createAsyncThunk('deleteUser', (data: any) => {
    try {
      return remove(`users/deleteUser/${data.userId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const createUser = createAsyncThunk('createUser', (data: any) => {
    try {
      return post(`/users/create`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });


const usersThunk = {
    getAllUsers,
    createUser,
    updateUser,
    deleteUser,
    activateUser,
    deactivateUser
  };
export default usersThunk;