import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store";
import alertsThunk from "../../store/thunks/alerts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";

function Dashboard() {
  const dispatch = useAppDispatch();
  const [freq, setFreq] = useState<string>("yearly");
  const store = useAppSelector((state: any) => state);
  const [allAlerts, setAllAlerts] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const { i18n, t } = useTranslation();
  const [label, setLabel] = useState<string>("");
  const [agentId, setAgentId] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [allUsers, setAllUsers] = useState<any>();
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [allRegions, setAllRegions] = useState<any>();
  const [allT, setAllT] = useState<any>();
  const [chartData, setChartData] = useState<{
    series: ApexAxisChartSeries;
    options: ApexOptions;
  }>({
    series: [
      {
        name: i18n.t("Alerts"),
        type: "column",
        color: "#ff5724",
        //#ff5724

        data: [440, 505, 414, 671, 227, 413, 201, 352, 552, 320, 257, 160],
      },
      {
        name: i18n.t("Interventions"),
        type: "column",
        data: [230, 420, 320, 500, 190, 340, 150, 300, 450, 260, 150, 130],
        color: "#10b981",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true, // Only show the download option
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        width: [0, 0],
      },
      plotOptions: {
        bar: {
          columnWidth: "34%", // Adjust bar width (percentage or pixel)
          // borderRadius: 8, // Add border radius for rounded corners
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: [],

      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: -20,
      },
    },
  });
  useEffect(() => {
    setAllAlerts(store.alerts?.allAlerts);
    setAllUsers(store.users?.usersList);
    setAllRegions(store.areas?.areasList);
    setAllT(store.alerts?.alertsTypes);
  },[store])
  const [donutChartData, setDonutChartData] = useState<{
    series: number[]; // Data series for donut chart
    options: ApexOptions;
  }>({
    series: [55, 45], // Example data series
    options: {
      chart: {
        type: "donut", // Set chart type to donut
      },
      labels: ["Sous-secteur 1", "Sous-secteur 2"], // Labels for the donut chart
      plotOptions: {
        pie: {
          donut: {
            size: "65%", // Adjust size of donut hole
          },
        },
      },
      colors: ["#FF008CFF", "#0099FFFF"], // Custom colors for each section
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#ffffff"], // Set the text color to white
        },
        formatter: (val: number) => `${val.toFixed(1)}%`, // Show percentage on each slice
      },
      tooltip: {
        y: {
          formatter: (val: number) => `${val} alerts`, // Tooltip format for value
        },
      },
    },
  });

  const addAlert = () => {
    dispatch(
      alertsThunk.createAlert({
        label: label,
        typeAlertId: selectedType ? selectedType : allT[0]?._id,
        userId: agentId ? agentId : allUsers[0]?._id,
        long: "",
        lat: "",
        regionId: selectedRegion ? selectedRegion : allRegions[0]?._id,
        // supRoleId: typeColor,
      })
    )
      .then((success: any) => {
        if (success?.error) {
          toast.error(i18n.t("An error occured"));
        } else {
          setRefreshList(true);
          toast.success(i18n.t("Success!"));
        }
        setShowModal(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setShowModal(false);
        toast.error("Error");
      });
  };
  const getAlerts = () => {
    setLoader(true);
    dispatch(alertsThunk.getAllAlerts({}))
      .then((success: any) => {
        setAllAlerts(success?.payload?.data?.list);
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (refreshList) {
      getAlerts();
      setRefreshList(false);
    }
  }, [refreshList]);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ToastContainer />
      <div
        className="grid grid-cols-12 gap-4 mt-5 sm:gap-5  px-[var(--margin-x)] transition-all duration-[.25s] lg:mt-6 lg:gap-6"
        style={{ width: "90vw" }}
      >
        <div id="pouletto" className="col-span-12 lg:col-span-6">
          <div className="text-xl font-semibold text-slate-700 dark:text-navy-100">
            {i18n.t("Dashboard")}
          </div>

          <div className="mt-4 grid grid-cols-2 gap-3 sm:mt-5  sm:gap-5 lg:mt-6">
            <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
              <div className="flex justify-between space-x-1">
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {allAlerts?.length}
                </p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5 text-error"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <p className="mt-1 text-xs+">{i18n.t("Alerts")}</p>
            </div>
            <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
              <div className="flex justify-between">
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  {allUsers?.length}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5 text-success"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <p className="mt-1 text-xs+">{i18n.t("Agents")}</p>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-12 gap-4 sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
            <div className="card col-span-12 lg:col-span-12">
              <div className="mt-3 flex flex-col justify-between px-4 sm:flex-row sm:items-center sm:px-5">
                <div className="flex flex-1 items-center justify-between space-x-2 sm:flex-initial">
                  <h2 className="text-sm+ font-medium tracking-wide text-slate-700 dark:text-navy-100">
                    {i18n.t("Overview")}
                  </h2>
                </div>
                {/* <div className="hidden space-x-2 sm:flex">
              <button className="btn h-8 rounded-full text-xs font-medium">
                Monthly
              </button>
              <button className="btn h-8 rounded-full text-xs+ font-medium active ">
                Yearly
              </button>
            </div> */}
              </div>
              <div className="ax-transparent-gridline mt-2 px-2">
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="line"
                  height={350}
                  width={"100%"}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-12 gap-4 sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
            <div className="card col-span-12 lg:col-span-12">
              <div className="mt-3 flex flex-col justify-between px-4 sm:flex-row sm:items-center sm:px-5">
                <div className="flex flex-1 items-center justify-between space-x-2 sm:flex-initial">
                  <h2 className="text-sm+ font-medium tracking-wide text-slate-700 dark:text-navy-100">
                    {i18n.t("Alerts by areas")}
                  </h2>
                </div>
                {/* <div className="hidden space-x-2 sm:flex">
              <button className="btn h-8 rounded-full text-xs font-medium">
                Monthly
              </button>
              <button className="btn h-8 rounded-full text-xs+ font-medium active ">
                Yearly
              </button>
            </div> */}
              </div>
              <div className="ax-transparent-gridline mt-2 px-2">
                <Chart
                  options={donutChartData.options}
                  series={donutChartData.series}
                  type="donut"
                  height={350}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 cu-mt">
          <div id="pouletta" className="card col-span-12 pb-4 sm:col-span-6">
            <div className="my-3 flex items-center justify-between px-4 sm:px-5">
              <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                {i18n.t("Ongoing Alerts")}
              </h2>
              <div
                x-data="usePopper({placement:'bottom-end',offset:4})"
                className="inline-flex"
              >
                <button
                  onClick={() => setShowModal(true)}
                  className="btn h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                  {i18n.t("Add New")}
                </button>
              </div>
            </div>
            <ol className="timeline line-space px-4 [--size:1.5rem] sm:px-5">
              {allAlerts?.slice(0, 5).map((alert: any, idx: number) => {
                const firstName = alert?.userInfo?.firstName || "";
                const lastName = alert?.userInfo?.lastName || "";
                const initials = `${firstName[0]}${lastName[0]}`;
                const colors = [
                  "bg-info",
                  "bg-error",
                  "bg-warning",
                  "bg-success",
                ];
                const backgroundColor = colors[idx % colors?.length];

                return (
                  <li
                    key={idx}
                    className="rounded-lg bg-slate-100 p-4 mt-4 dark:bg-navy-600"
                  >
                    <div className="timeline-item-content flex-1 pl-4 sm:pl-8">
                      <div className="flex flex-col justify-between pb-2 sm:flex-row sm:pb-0">
                        <p className="pb-2 font-medium leading-none text-slate-600 dark:text-navy-100 sm:pb-0">
                          {alert?.label}
                        </p>
                        <span className="text-xs text-slate-400 dark:text-navy-300">
                          {moment(alert?.createdAt).fromNow()}
                        </span>
                      </div>
                      <p className="py-1"> {alert?.description}</p>
                      <a
                        href="#"
                        className="inline-flex items-center space-x-1 pt-2 text-slate-600 transition-colors hover:text-primary dark:text-navy-100 dark:hover:text-accent"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                        <span>File_final.fig</span>
                      </a>
                      <div className="pt-2 pb-2 flex items-center justify-between">
                        <div className="mr-2">
                          <span className="text-primary dark:text-accent">
                            {
                              allRegions.find(
                                (region: any) => region?._id === alert?.regionId
                              )?.label
                            }
                          </span>
                          <span
                            className="tag rounded-full border dark:text-warning border-warning/30 bg-warning/10 text-warning"
                            style={{ marginLeft: "1vw" }}
                          >
                            {alert?.typeAlertInfo?.label}
                          </span>
                        </div>
                        <div className="flex flex-wrap -space-x-2">
                          <div
                            className={`avatar size-8 hover:z-10`}
                            style={{ height: "2rem", width: "2rem" }}
                          >
                            <div
                              className={`is-initial rounded-full text-xs+ uppercase ${backgroundColor} text-white ring ring-white dark:ring-navy-700`}
                              title={`${firstName} ${lastName}`}
                              style={{ cursor: "pointer" }}
                            >
                              {initials}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
            <Link
              to={"/alerts"}
              className="text-right text-primary dark:text-accent mt-2 mr-4"
            >
              {i18n.t("See More")}
            </Link>
          </div>
        </div>

        {showModal && (
          <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
            <div
              className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
              <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                  {i18n.t("Add Alert")}
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-4.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
                <p>
                  {i18n.t("The new alert you'll add will be displayed to all the concerned people")}.
                </p>
                <div className="mt-4 space-y-4">
                  <label className="block">
                    <span>{i18n.t("Name")}:</span>
                    <input
                      defaultValue={label}
                      onChange={(e) => setLabel(e.target.value)}
                      placeholder={i18n.t("Write a short name")}
                      className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    />
                  </label>

                  <label className="block">
                    <span>{i18n.t("Choose agent")}:</span>
                    <select
                      className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                      onChange={(e) => setAgentId(e.target.value)}
                    >
                      {allUsers?.map((user: any, idx: number) => (
                        <option value={user?._id} key={idx}>
                          {user?.firstName} {user?.lastName}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block">
                    <span>{i18n.t("Choose area")}:</span>
                    <select
                      className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                      defaultValue={selectedRegion}
                      onChange={(e) => setSelectedRegion(e.target.value)}
                    >
                      {allRegions?.map((region: any, idx: number) => (
                        <option value={region?._id} key={idx}>
                          {region?.label}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="block">
                    <span>{i18n.t("Choose alert type")}:</span>
                    <select
                      className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                      defaultValue={selectedRegion}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      {allT?.map((type: any, idx: number) => (
                        <option value={type?._id} key={idx}>
                          {type?.label}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label className="block">
                    <span>Description:</span>
                    <textarea
                      rows={4}
                      placeholder={i18n.t("Describe quickly what's the issue")}
                      className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    ></textarea>
                  </label>

                  {/* <label className="block">
           <span>Website Address:</span>
           <input
             type="text"
             placeholder="URL Address"
             className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
           />
         </label>

         <label className="inline-flex items-center space-x-2">
           <input
             type="checkbox"
             className="form-switch is-outline h-5 w-10 rounded-full border border-slate-400/70 bg-transparent before:rounded-full before:bg-slate-300 checked:border-primary checked:before:bg-primary dark:border-navy-400 dark:before:bg-navy-300 dark:checked:border-accent dark:checked:before:bg-accent"
           />
           <span>Public pin</span>
         </label> */}

                  <div className="space-x-2 text-right">
                    <button
                      onClick={() => setShowModal(false)}
                      className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                    >
                      {i18n.t("Cancel")}
                    </button>
                    <button
                      onClick={() => addAlert()}
                      className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                      {i18n.t("Add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
