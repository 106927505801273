import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  //timeout: 60000,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

export async function get<T>(
  path: string,
  config?: AxiosResponse,
): Promise<AxiosResponse<T>> {
  return axiosInstance.get(path, config)
}

export async function remove<T>(
  path: string,
  config?: AxiosResponse,
): Promise<AxiosResponse<T>> {
  return axiosInstance.delete(path, config)
}

export async function patch<T>(
  path: string,
  body: any,
  config?: AxiosResponse,
): Promise<AxiosResponse<T>> {
  return axiosInstance.patch(path, body, config)
}

export async function post<T=any>(
  path: string,
  body: any,
  config?: AxiosResponse,
): Promise<AxiosResponse<T>> {
  return axiosInstance.post(path, body, config)
}
export async function put<T=any>(
  path: string,
  body?: any,
  config?: AxiosResponse,
): Promise<AxiosResponse<T>> {
  return axiosInstance.put(path, body, config)
}