import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {authReducer} from '../store/slices/auth.slice'; 
import {alertsReducer} from '../store/slices/alerts.slice'; 
import {areasReducer} from '../store/slices/areas.slice'; 
import {usersReducer} from '../store/slices/users.slice'; 
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

const persistConfig = (key:string)=> ({
  key,
  version: 1,
  storage,
});

// Persisted reducer for authentication
const persistedAuthReducer = persistReducer(persistConfig('auth'), authReducer);
const persistedAlertsReducer = persistReducer(persistConfig('alerts'), alertsReducer);
const persistedAreasReducer = persistReducer(persistConfig('areas'), areasReducer);
const persistedUsersReducer = persistReducer(persistConfig('users'), usersReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    alerts:persistedAlertsReducer,
    areas: persistedAreasReducer,
    users:persistedUsersReducer,
    // other reducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // redux-persist requires disabling this check
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Create typed versions of useDispatch and useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistStoreData = persistStore(store);
export default store;
