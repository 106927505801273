import React from "react";

const AppFooter: React.FC = () => {
  return (
    <footer className="footer mt-auto py-3 bg-white text-center">
      {/* {" "}
      <div className="container">
        {" "}
        <span className="text-muted">
          {" "}
          Copyright © <span id="year">2024</span>{" "}
          <a href="javascript:void(0);" className="text-dark fw-semibold">
            Ynex
          </a>
          . Designed with <span className="bi bi-heart-fill text-danger" /> by{" "}
          <a href="javascript:void(0);">
            {" "}
            <span className="fw-semibold text-primary text-decoration-underline">
              Spruko
            </span>{" "}
          </a>{" "}
          All rights reserved{" "}
        </span>{" "}
      </div>{" "} */}
    </footer>
  );
};

export default AppFooter;
