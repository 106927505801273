import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import loginThunk from '../thunks/login'; 

interface User {
    id: string ;
    firstName: string;
    lastName: string;
    email: string;
    activate: boolean;
    phoNumber: number;
}
interface LoginResponse {
  accessToken: string;
  user: {
    id: string ;
    firstName: string;
    lastName: string;
    email: string;
    activate: boolean;
    phoNumber: number;
  };
}

interface LoginError {
  message: string; 
}
interface AuthState {
  user: User | null;
  accessToken: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  accessToken: null,
  status: 'idle',
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        loginThunk.login.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.status = 'succeeded';
          state.accessToken = action.payload.accessToken;
          state.user = action.payload.user;
          localStorage.setItem('token', action.payload.accessToken);
        }
      )
      .addCase(
        loginThunk.login.rejected,
        (state, action: PayloadAction<LoginError | string | undefined>) => {
          state.status = 'failed';
          state.error = typeof action.payload === 'string' ? action.payload : action.payload?.message || 'Unknown error';
        }
      );
  },
});

export const { logout } = authSlice.actions;
export  const authReducer = authSlice.reducer;
