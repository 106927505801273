import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import areasThunk from "../../store/thunks/areas";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";


function AreasManage(props: any) {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const store = useAppSelector((state: any) => state);
  const [allT, setAllT] = useState(store.areas?.areasList);
  const [typeLabel, setTypeLabel] = useState<string>("");
  const [typeDesc, setTypeDesc] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [shouldRefreshDep, setShouldRefreshDep] = useState(false);
  const [roleModal, setroleModal] = useState(false);
  const [depModal, setDepModal] = useState(false);
  const [deleteMod, setDeleteMod] = useState(false);
  const [activeCountry, setActiveCountry] = useState<any>({});
  const [dep, setDep] = useState<any>([]);
  const [selectedDep, setSelectedDep] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [deplo, setDepLo] = useState(true);
  const [depart, setDepart] = useState(false);
  const [regionLoader, setRegionLoader] = useState(false);
  const activeUser = store.auth?.user;

  const handleAddRegion = () => {
    setRegionLoader(true);
    dispatch(
      areasThunk.createRegion({
        label: typeLabel,
        description: typeDesc,
        departmentId: selectedDep? selectedDep : dep[0]?._id,
        // supRoleId: typeColor,
      })
    )
      .then((success: any) => {
        if (success?.payload?.data?.error) {
          toast.error(success?.payload?.data?.message);
        } else {
          toast.success(i18n.t("Success!"));
          setShouldRefresh(true);
        }
        setRegionLoader(false);
        setroleModal(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setRegionLoader(false);
        toast.error("Error");
      });
  };
  const handleAddDep = () => {
    setDepart(true);
    dispatch(
      areasThunk.createDepartment({
        label: typeLabel,
        countryId: activeCountry?._id,
        //description: typeDesc,
        // supRoleId: typeColor,
      })
    )
      .then((success: any) => {
        setDepModal(false);
        setDepart(false);
        setShouldRefreshDep(true);
        toast.success("Success!");
      })
      .catch((error: any) => {
        setDepModal(false);
        setDepart(false);
        console.log("errr", error);
        toast.error("Error");
      });
  };

  const handleDeleteType = (data: any) => {
    // dispatch(areasThunk.de(data))
    //   .then((success: any) => {
    //     //console.log("role deleted", success);
    //     setShouldRefresh(true);
    //     setroleModal(false);
    //     toast.success("Success!");
    //   })
    //   .catch((error: any) => {
    //     console.log("errr", error);
    //     setroleModal(false);
    //     toast.error("Error");
    //   });
  };
  const handleConfirm = (roleType: any) => {
    return (
      <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
        <div
          className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
          onClick={() => setDeleteMod(false)}
        ></div>
        <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
          <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
            <div className="mt-4 space-y-4 text-center">
              <p
                className="text-error"
                style={{
                  textTransform: "uppercase",
                  fontSize: "larger",
                  fontWeight: "600",
                }}
              >
                Attention!
              </p>
              <p>Are you sure you want to remove this role type?</p>
              <div className="space-x-2 text-center">
                <button
                  onClick={() => setDeleteMod(false)}
                  className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleDeleteType(roleType)}
                  className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-error dark:hover:bg-error-focus dark:focus:bg-error-focus dark:active:bg-error/90"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getAllTypes = () => {
    setLoader(true);
    dispatch(areasThunk.getAllRegions({}))
      .then((success: any) => {
        setAllT(success?.payload?.data?.regionList);
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setLoader(false);
      });
  };
  const getCountries = () => {
    // setLoader(true);
    dispatch(areasThunk.getAllCountries({}))
      .then((success: any) => {
        setActiveCountry(success?.payload?.data?.list[0]);
        //setLoader(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        // setLoader(false);
      });
  };

  const getAllDep = () => {
    setDepLo(true);
    dispatch(
      areasThunk.getAllDepartmentsByCountry({
        countryId: activeCountry?._id,
      })
    )
      .then((success: any) => {
        setDep(success?.payload?.data?.list);
        setDepLo(false);
        // setShouldRefreshDep(true);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setDepLo(false);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    if (activeCountry?._id) {
      getAllDep();
      setTypeLabel("");
      //setShouldRefreshDep(false);
    }
  }, [activeCountry]);
  useEffect(() => {
    if (shouldRefreshDep) {
      getAllDep();
      setTypeLabel("");
      setShouldRefreshDep(false);
    }
  }, [shouldRefreshDep]);

  useEffect(() => {
    if (shouldRefresh) {
      getAllTypes();
      setTypeLabel("");
      setTypeDesc("");
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const display = () => {
    if (allT?.length > 0) {
      return (
        <div className="">
          {allT.map((region: any, idx:number) => (
            <span
              key={idx}
              className="tag rounded-full mr-2 mt-2 border bg-slate/10 border-slate/30 bg-slate/10 text-slate"
            >
              {region?.label}
              {/* <i
                className="custom-danger mx-2 my-1 fa-solid fa-xmark"
                onClick={() => handleDeleteType(region)}
              ></i> */}
            </span>
          ))}
        </div>
      );
    } else if (loader) {
      return (
        <div className="mt-2">
          <Spinner size={4} />
        </div>
      );
    } else {
      return i18n.t("No data");
    }
  };

  const displayDep = () => {
    if (dep?.length > 0) {
      return (
        <div className="">
          {dep.map((de: any, idx: number) => (
            <span
              key={idx}
              className="tag rounded-full mr-2 mt-2 border bg-slate/10 border-slate/30 bg-slate/10 text-slate"
            >
              {de?.label}
              {/* <i
                className="custom-danger mx-2 my-1 fa-solid fa-xmark"
                onClick={() => handleDeleteType(de)}
              ></i> */}
            </span>
          ))}
        </div>
      );
    } else if (deplo) {
      return (
        <div className="mt-2">
          <Spinner size={4} />
        </div>
      );
    } else {
      return i18n.t("No data");
    }
  };

  return (
    <div>
      <div className="card">
        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
            {i18n.t("Areas")}
          </h2>
          <span className="space-x-2">
            <button
              onClick={() => setroleModal(true)}
              className="btn min-w-[7rem] bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              {i18n.t("Add New Area")}
            </button>
          </span>
        </div>
        <div className="p-4 sm:p-5">
          <div className="flex flex-col">
            <div className="text-base font-medium text-slate-600 dark:text-navy-100">
              {i18n.t("Country")}{" "}
              <span className="tag rounded-full mr-2 border bg-warning/10 border-warning/30 bg-warning/10 text-warning">
                {activeCountry?.label}
              </span>{" "}
            </div>
            <span className="text-base font-medium text-slate-600 dark:text-navy-100 mt-5">
              {i18n.t("All Areas")} ({allT?.length})
            </span>
            <div className="">{display()}</div>
            <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
            <div className="flex flex-col items-center space-y-4 p-4  sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
              <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                {i18n.t("Departments")} ({dep?.length})
              </h2>
              <span className="space-x-2">
                <span
                  onClick={() => setDepModal(true)}
                  className="font-medium text-primary-100 "
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-primary-100 dark:text-navy-100"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </span>
              </span>
            </div>
            <div className="mx-5">{displayDep()}</div>
          </div>
        </div>
      </div>
      {roleModal && (
        <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
          <div
            className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
            onClick={() => setroleModal(false)}
          ></div>
          <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
            <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
              <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                {i18n.t("Add Area")}
              </h3>
              <button
                onClick={() => setroleModal(false)}
                className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-4.5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
              <p>{i18n.t("Send the request to add a new area")}.</p>
              <div className="mt-4 space-y-4">
                <label className="block">
                  <span>{i18n.t("Department")}:</span>
                  <select
                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                    onChange={(e) => setSelectedDep(e.target.value)}
                    value={selectedDep}
                  >
                    {dep?.map((de: any, idx: number) => (
                      <option value={de?._id} key={idx}>
                        {de?.label}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="block">
                  <span>{i18n.t("Area Label")}:</span>
                  <input
                    defaultValue={typeLabel}
                    onChange={(e) => setTypeLabel(e.target.value)}
                    type="text"
                    placeholder={i18n.t("Area Label")}
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  />
                </label>
                <label className="block">
                  <span>{i18n.t("Area Description")}:</span>
                  <textarea
                    defaultValue={typeDesc}
                    onChange={(e) => setTypeDesc(e.target.value)}
                    rows={4}
                    placeholder={i18n.t("A short description of the area")}
                    className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  ></textarea>
                </label>

                <div className="space-x-2 text-right">
                  <button
                    onClick={() => setroleModal(false)}
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                  >
                    {i18n.t("Cancel")}
                  </button>
                  <button
                    onClick={() => handleAddRegion()}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  >
                    {regionLoader ? <Spinner size={4} /> : i18n.t("Add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {depModal && (
        <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
          <div
            className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
            onClick={() => setDepModal(false)}
          ></div>
          <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
            <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
              <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                {i18n.t("Add Department")}
              </h3>
              <button
                onClick={() => setDepModal(false)}
                className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-4.5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
              <p>{i18n.t("Send the request to add a new department")}.</p>
              <div className="mt-4 space-y-4">
                <label className="block">
                  <span>{i18n.t("Department Label")}:</span>
                  <input
                    defaultValue={typeLabel}
                    onChange={(e) => setTypeLabel(e.target.value)}
                    type="text"
                    placeholder={i18n.t("Department Label")}
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  />
                </label>

                <div className="space-x-2 text-right">
                  <button
                    onClick={() => setDepModal(false)}
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                  >
                    {i18n.t("Cancel")}
                  </button>
                  <button
                    onClick={() => handleAddDep()}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  >
                    {depart ? <Spinner /> : i18n.t("Add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AreasManage;
