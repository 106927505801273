import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../helpers/axios.helper';
import { get } from '../../helpers/axios.helper';
import { remove } from '../../helpers/axios.helper';
import { put } from '../../helpers/axios.helper';


const getAllRoles = createAsyncThunk('getAllRoles', (data: any) => {
    try {
      return get(`roles/allRoles`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const findUserRole = createAsyncThunk('findUserRole', (data: any) => {
    try {
      return get(`users/findUser/${data.userId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const createRole = createAsyncThunk('createRole', (data: any) => {
    try {
      return post(`roles/create`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const updateRole = createAsyncThunk('updateRole', (data: any) => {
    try {
      return put(`roles/updateRole/${data.roleId}`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const deleteRole = createAsyncThunk('deleteRole', (data: any) => {
    try {
      return remove(`roles/deleteRole/${data._id}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });


const agentsThunk = {
    getAllRoles,
    createRole,
    deleteRole,
    updateRole,
    findUserRole
  };
export default agentsThunk;