import React from 'react'

function Spinner(props:any) {
  return (
    <div
    className={`spinner size-${props?.size} animate-spin rounded-full border-[3px] border-secondary border-r-transparent`}
  ></div>
  )
}

export default Spinner