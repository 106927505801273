// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline .timeline-item:last-child:before .timeline-item:before{
  --tw-gradient-from: '' !important;
  --tw-gradient-to:'' !important;
  --tw-gradient-stops: '' !important;
  --tw-gradient-to: '' !important;
  background-image: none;
  width: 0px !important;
  content: 0!important;
}

/* .hZZlND{
  overflow: visible !important;
} */
.custom-danger{

}
.custom-danger:hover{
  color: red;
}

.screen-cover{
  width:90vw !important;
}
.half-screen{
  width: 50vw !important;
}
.cu-mt{
  margin-top: 5.6vh !important;
}


@media (max-width: 1023px) {
  .cu-mt{
    margin-top: 1vh !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,8BAA8B;EAC9B,kCAAkC;EAClC,+BAA+B;EAC/B,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;;GAEG;AACH;;AAEA;AACA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,4BAA4B;AAC9B;;;AAGA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".timeline .timeline-item:last-child:before .timeline-item:before{\n  --tw-gradient-from: '' !important;\n  --tw-gradient-to:'' !important;\n  --tw-gradient-stops: '' !important;\n  --tw-gradient-to: '' !important;\n  background-image: none;\n  width: 0px !important;\n  content: 0!important;\n}\n\n/* .hZZlND{\n  overflow: visible !important;\n} */\n.custom-danger{\n\n}\n.custom-danger:hover{\n  color: red;\n}\n\n.screen-cover{\n  width:90vw !important;\n}\n.half-screen{\n  width: 50vw !important;\n}\n.cu-mt{\n  margin-top: 5.6vh !important;\n}\n\n\n@media (max-width: 1023px) {\n  .cu-mt{\n    margin-top: 1vh !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
