import React, { useEffect } from "react";
import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import Content from "./Content";
import AppSidebar from "./AppSidebar";
import { AppProvider } from "./context/AppContext";
import ErrorBoundary from "../app/components/ErrorBoundary";
import { useAppDispatch, useAppSelector } from "../store";
import areasThunk from "../store/thunks/areas";
import alertsThunk from "../store/thunks/alerts";
import usersThunk from "../store/thunks/users";



const Layout: React.FC = () => {
  const dispatch = useAppDispatch();

  const getAllRegions = () => {
    dispatch(areasThunk.getAllRegions({})) 
  };
  const getAllTypes = () => {
    dispatch(alertsThunk.getAllAlertTypes({}))
  };
  const getAllUsers = () => {
    dispatch(usersThunk.getAllUsers({}))
  };
  const getAlerts = () => {
    dispatch(alertsThunk.getAllAlerts({}))
  };
  useEffect(()=>{
    getAllRegions();
    getAllTypes();
    getAllUsers();
    getAlerts();
  },[])

  return (
    <AppProvider>
      <div className="flex flex-column min-h-screen">
        <ErrorBoundary>
          <AppTopbar />
        </ErrorBoundary>

        <ErrorBoundary>
          <AppSidebar />
        </ErrorBoundary>

        <ErrorBoundary>
          <Content />
        </ErrorBoundary>

        <ErrorBoundary>
          <AppFooter />
        </ErrorBoundary>
      </div>
    </AppProvider>
  );
};

export default Layout;
