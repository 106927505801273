import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../helpers/axios.helper';
import { get } from '../../helpers/axios.helper';


const getAllCountries = createAsyncThunk('getAllCountries', (data: any) => {
    try {
      return get(`country/getAllCountry`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getAllDepartmentsByCountry = createAsyncThunk('getAllDepartmentsByCountry', (data: any) => {
    try {
      return get(`departments/allDepartmentByCountry/${data.countryId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const createDepartment = createAsyncThunk('createDepartment', (data: any) => {
    try {
      return post(`departments/createDepartment/${data.countryId}`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const createRegion = createAsyncThunk('createRegion', (data: any) => {
    try {
      return post(`region/create/${data.departmentId}`,data);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getAllRegionsByDepartment = createAsyncThunk('getAllRegionsByDepartment', (data: any) => {
    try {
      return get(`region/allByDepartment/${data.departmentId}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getAllRegionsByCountry = createAsyncThunk('getAllRegionsByCountry', (data: any) => {
    try {
      return get(`region/allByCountry/${data.countryd}`);
    } catch (error) {
      console.error('Error:', error);
    }
  });

  const getAllRegions = createAsyncThunk('getAllRegions', (data: any) => {
    try {
      return get(`region/allRegion`);
    } catch (error) {
      console.error('Error:', error);
    }
  });


const areasThunk = {
    getAllCountries,
    getAllDepartmentsByCountry,
    createDepartment,
    getAllRegionsByCountry,
    getAllRegionsByDepartment,
    createRegion,
    getAllRegions
  };
export default areasThunk;