import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import alertsThunk from '../thunks/alerts'; 

interface AlertState {
    alertsTypes: any;
    allAlerts: any;
    loading: boolean;
    error: any;
}
const initialState: AlertState = {
    alertsTypes: [],
    allAlerts: [],
    loading: false,
    error: false,
};
const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(alertsThunk.getAllAlertTypes.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(alertsThunk.getAllAlertTypes.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.alertsTypes = action.payload.data.list; 
        })
        .addCase(alertsThunk.getAllAlertTypes.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch alert types';
        })
        .addCase(alertsThunk.getAllAlerts.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(alertsThunk.getAllAlerts.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.allAlerts = action.payload.data.list; 
        })
        .addCase(alertsThunk.getAllAlerts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to fetch alerts';
        });
    }
  });
  
  export const selectAlertTypes = (state: any) => state.alerts.alertTypes;
  export const selectAlerts = (state: any) => state.alerts.allAlerts;
  export const alertsReducer = alertsSlice.reducer;