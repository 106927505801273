import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import rolesThunk from "../../store/thunks/roles";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";


function RolesManage(props: any) {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const [allT, setAllT] = useState([]);
  const [typeLabel, setTypeLabel] = useState<string>("");
  const [typeDesc, setTypeDesc] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [roleModal, setroleModal] = useState(false);
  const [deleteMod, setDeleteMod] = useState(false);
  const [loader, setLoader] = useState(false);
  const [roleLoader, setRoleLoader] = useState(false);
  const store = useAppSelector((state: any) => state);
  const activeUser = store.auth?.user;

  const handleAddRole = () => {
    setRoleLoader(true);
    dispatch(
      rolesThunk.createRole({
        label: typeLabel,
        description: typeDesc,
        // supRoleId: typeColor,
      })
    )
      .then((success: any) => {
        //console.log("role added", success);
        setShouldRefresh(true);
        setRoleLoader(false);
        setroleModal(false);
        toast.success("Success!");
      })
      .catch((error: any) => {
        console.log("errr", error);
        setRoleLoader(false);
        toast.error("Error");
      });
  };

  const handleDeleteType = (data: any) => {
    dispatch(rolesThunk.deleteRole(data))
      .then((success: any) => {
        //console.log("role deleted", success);
        setShouldRefresh(true);
        setroleModal(false);
        toast.success("Success!");
      })
      .catch((error: any) => {
        console.log("errr", error);
        setroleModal(false);
        toast.error("Error");
      });
  };
  const handleConfirm = (roleType: any) => {
    return (
      <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
        <div
          className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
          onClick={() => setDeleteMod(false)}
        ></div>
        <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
          <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
            <div className="mt-4 space-y-4 text-center">
              <p
                className="text-error"
                style={{
                  textTransform: "uppercase",
                  fontSize: "larger",
                  fontWeight: "600",
                }}
              >
                Attention!
              </p>
              <p>Are you sure you want to remove this role type?</p>
              <div className="space-x-2 text-center">
                <button
                  onClick={() => setDeleteMod(false)}
                  className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleDeleteType(roleType)}
                  className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-error dark:hover:bg-error-focus dark:focus:bg-error-focus dark:active:bg-error/90"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getAllTypes = () => {
    setLoader(true);
    dispatch(rolesThunk.getAllRoles({}))
      .then((success: any) => {
        setAllT(success?.payload?.data?.roleData);
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setLoader(false);
      });
  };

  const getUrole = () => {
    dispatch(rolesThunk.findUserRole({
        userId: activeUser?._id
    }))
      .then((success: any) => {
       //console.log('annn', success)
      })
      .catch((error: any) => {
        console.log("errr", error);
        
      });
  };

  useEffect(() => {
    getAllTypes();
    //getUrole();
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      getAllTypes();
      setTypeLabel("");
      setTypeDesc("");
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const display = () => {
    if (allT?.length > 0) {
      return (
        <div className="mt-2">
          {allT.map((role: any, idx) => (
            <span
              key={idx}
              className="tag mt-2 rounded-full mr-2 border bg-slate/10 border-slate/30 bg-slate/10 text-slate"
            >
              {role?.label}
              <i
                className="custom-danger mx-2 my-1 fa-solid fa-xmark"
                onClick={() => handleDeleteType(role)}
              ></i>
            </span>
          ))}
        </div>
      );
    } else if (loader) {
      return (
        <div className="mt-2">
          <Spinner size={4} />
        </div>
      );
    } else {
      return "No data";
    }
  };

  return (
    <div>
      <div className="card">
        <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
          <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
          {i18n.t("Roles")}
          </h2>
          <span className="space-x-2">
            <button
              onClick={() => setroleModal(true)}
              className="btn min-w-[7rem] bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              {i18n.t("Add New Role")}
            </button>
          </span>
        </div>
        <div className="p-4 sm:p-5">
          <div className="flex flex-col">
            <span className="text-base font-medium text-slate-600 dark:text-navy-100">
              {i18n.t("All")} ({allT?.length})
            </span>

            <div className="">{display()}</div>
          </div>
        </div>
      </div>
      {roleModal && (
        <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
          <div
            className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
            onClick={() => setroleModal(false)}
          ></div>
          <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
            <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
              <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                {i18n.t("Add Role")} 
              </h3>
              <button
                onClick={() => setroleModal(false)}
                className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-4.5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
              <p>{i18n.t("Send the request to add a new role")}.</p>
              <div className="mt-4 space-y-4">
                <label className="block">
                  <span>{i18n.t("Role Label")}:</span>
                  <input
                    defaultValue={typeLabel}
                    onChange={(e) => setTypeLabel(e.target.value)}
                    type="text"
                    placeholder={i18n.t("Role Label")}
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  />
                </label>
                <label className="block">
                  <span>{i18n.t("Role Description")}:</span>
                  <textarea
                    defaultValue={typeDesc}
                    onChange={(e) => setTypeDesc(e.target.value)}
                    rows={4}
                    placeholder={i18n.t("A short description of the role")}
                    className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  ></textarea>
                </label>

                <div className="space-x-2 text-right">
                  <button
                    onClick={() => setroleModal(false)}
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                  >
                    {i18n.t("Cancel")}
                  </button>
                  <button
                    onClick={() => handleAddRole()}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  >
                    {roleLoader ? <Spinner size={4} /> : i18n.t("Add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RolesManage;
