import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import alertsThunk from "../../store/thunks/alerts";
import { ToastContainer, toast } from "react-toastify";
import AlertTypeManage from "../components/AlertTypeManage";
import RolesManage from "../components/RolesManage";
import AreasManage from "../components/AreasManage";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../components/ErrorBoundary";

function Settings() {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state: any) => state);
  const activeUser = store.auth?.user;
  const [activeTab, setActiveTab] = useState<string>("account");
  const [shouldRefreshTypes, setShouldRefreshTypes] = useState(false);
  const { i18n, t } = useTranslation();


  return (
    <>
      <ToastContainer />
      <div
        className="grid gap-4 px-[var(--margin-x)] transition-all duration-[.25s] mt-5 sm:gap-5 lg:mt-6 lg:gap-6"
        style={{ width: "90vw" }}
      >
        <div className="text-xl font-semibold text-slate-700 dark:text-navy-100">
          {i18n.t("Settings")}
        </div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
          <div className="col-span-12 lg:col-span-4">
            <div className="card p-4 sm:p-5">
              <div className="flex items-center space-x-4">
                <div className="avatar size-14">
                  <img
                    className="rounded-full"
                    src="assets/images/avatar/avatar-12.jpg"
                    alt="avatar"
                  />
                </div>
                <div>
                  <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                    {activeUser?.firstName} {activeUser?.lastName}
                  </h3>
                  <p className="text-xs+">{activeUser?.email}</p>
                </div>
              </div>
              <ul className="mt-6 space-y-1.5 font-inter font-medium">
                <li>
                  <div
                    onClick={() => setActiveTab("account")}
                    style={{ cursor: "pointer" }}
                    className={`flex items-center space-x-2 rounded-lg ${
                      activeTab === "account"
                        ? "dark:bg-accent bg-primary text-white"
                        : ""
                    } px-4 py-2.5 tracking-wide outline-none transition-all `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{i18n.t("Account")}</span>
                  </div>
                </li>
                {/* <li>
                <a
                  className="group flex space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span>Notification</span>
                </a>
              </li>
              <li>
                <a
                  className="group flex space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <span>Security</span>
                </a>
              </li>
              <li>
                <a
                  className="group flex space-x-2 rounded-lg px-4 py-2.5 tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 text-slate-400 transition-colors group-hover:text-slate-500 group-focus:text-slate-500 dark:text-navy-300 dark:group-hover:text-navy-200 dark:group-focus:text-navy-200"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                    />
                  </svg>
                  <span>Apps</span>
                </a>
              </li> */}
                <li>
                  <div
                    onClick={() => setActiveTab("administration")}
                    style={{ cursor: "pointer" }}
                    className={`flex items-center space-x-2 rounded-lg ${
                      activeTab === "administration"
                        ? "dark:bg-accent bg-primary text-white"
                        : ""
                    } px-4 py-2.5 tracking-wide outline-none transition-all `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                      />
                    </svg>
                    <span>{i18n.t("Administration")}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {activeTab === "account" && (
            <div className="col-span-12 lg:col-span-8">
              <div className="card">
                <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
                  <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
                    {i18n.t("Account Settings")}
                  </h2>
                  {/* <div className="flex justify-center space-x-2">
                <button className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  Cancel
                </button>
                <button className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                  Save
                </button>
              </div> */}
                </div>
                <div className="p-4 sm:p-5">
                  <div className="flex flex-col">
                    <span className="text-base font-medium text-slate-600 dark:text-navy-100">
                      Avatar
                    </span>
                    <div className="avatar mt-1.5 size-20">
                      <img
                        className="mask is-squircle"
                        src="assets/images/avatar/avatar-12.jpg"
                        alt="avatar"
                      />
                      <div className="absolute bottom-0 right-0 flex items-center justify-center rounded-full bg-white dark:bg-navy-700">
                        <button className="btn size-6 rounded-full border border-slate-200 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:border-navy-500 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="my-7 h-px bg-slate-200 dark:bg-navy-500" />
                  <div
                    className="grid grid-cols-1 gap-4 sm:grid-cols-2 "
                    style={{ marginBottom: "10px" }}
                  >
                    {/* <label className="block">
                  <span>Display name </span>
                  <span className="relative mt-1.5 flex">
                    <input
                      className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Enter name"
                      type="text"
                    />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                      <i className="fa-regular fa-user text-base" />
                    </span>
                  </span>
                </label> */}
                    <label className="block">
                      <span>{i18n.t("Full Name")} </span>
                      <span className="relative mt-1.5 flex">
                        <input
                          className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder={i18n.t("Enter full name")}
                          type="text"
                          defaultValue={`${activeUser?.firstName} ${activeUser?.lastName}`}
                          disabled
                        />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                          <i className="fa-regular fa-user text-base" />
                        </span>
                      </span>
                    </label>
                    <label className="block">
                      <span>{i18n.t("Email Address")}</span>
                      <span className="relative mt-1.5 flex">
                        <input
                          className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder={i18n.t("Enter email address")}
                          type="text"
                          style={{
                            backgroundImage: 'url("data:image/png',
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 20,
                            backgroundPosition: "97% center",
                            cursor: "auto",
                          }}
                          data-temp-mail-org={0}
                          defaultValue={activeUser?.email}
                          disabled
                        />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                          <i className="fa-regular fa-envelope text-base" />
                        </span>
                      </span>
                    </label>
                    <label className="block">
                      <span>{i18n.t("Phone Number")}</span>
                      <span className="relative mt-1.5 flex">
                        <input
                          className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder={i18n.t("Enter phone number")}
                          type="text"
                          defaultValue={activeUser?.phoneNumber}
                          disabled
                        />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                          <i className="fa fa-phone" />
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "administration" && (
            <>
              <div className="col-span-12 lg:col-span-8">
               <ErrorBoundary><AreasManage /></ErrorBoundary>
                <div className="mt-5">
                <ErrorBoundary><AlertTypeManage /></ErrorBoundary>
                </div>
                <div className="mt-5">
                <ErrorBoundary><RolesManage /></ErrorBoundary>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Settings;
