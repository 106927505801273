import React, { useState , useRef, useEffect} from "react";
import { useAppContext } from "./context/AppContext";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store";
import { logout } from "../store/slices/auth.slice";

const AppSidebar: React.FC = () => {
  const navigate = useNavigate();
  const { toggleSidebar, isSidebarOpen } = useAppContext();
  const { i18n, t } = useTranslation();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const store = useAppSelector((state: any) => state);
  const activeUser = store.auth?.user;
  const popperRef:any = useRef(null);
  const popperRootRef:any = useRef(null);

  const handleClickOutside = (event:any) => {
    // Check if click is outside popper elements
    if (
      popperRef.current &&
      popperRootRef.current &&
      !popperRef.current.contains(event.target) &&
      !popperRootRef.current.contains(event.target)
    ) {
      setIsProfileOpen(false);
    }
  };

  useEffect(() => {
    if (isProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileOpen]);

  return (
    <>
      <div
        className={`sidebar print:hidden ${
          isSidebarOpen ? "is-sidebar-open" : ""
        }`}
      >
        {/* Main Sidebar */}
        <div className="main-sidebar">
          <div className="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800">
            {/* Application Logo */}
            <div className="flex pt-4">
              <Link to="/dashboard">
                <img
                  className="size-11 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"
                  src="assets/images/app-logo.svg"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="is-scrollbar-hidden flex grow flex-col space-y-4 overflow-y-auto pt-6">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 
     hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25  dark:hover:bg-navy-450 
     dark:focus:bg-navy-450 ${
       isActive
         ? "bg-primary/10 text-primary dark:text-accent-light dark:active:bg-navy-450/90 dark:bg-navy-600"
         : ""
     }`
                }
              >
                <svg
                  className="size-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fillOpacity=".3"
                    d="M5 14.059c0-1.01 0-1.514.222-1.945.221-.43.632-.724 1.453-1.31l4.163-2.974c.56-.4.842-.601 1.162-.601.32 0 .601.2 1.162.601l4.163 2.974c.821.586 1.232.88 1.453 1.31.222.43.222.935.222 1.945V19c0 .943 0 1.414-.293 1.707C18.414 21 17.943 21 17 21H7c-.943 0-1.414 0-1.707-.293C5 20.414 5 19.943 5 19v-4.94Z"
                  />
                  <path
                    fill="currentColor"
                    d="M3 12.387c0 .267 0 .4.084.441.084.041.19-.04.4-.204l7.288-5.669c.59-.459.885-.688 1.228-.688.343 0 .638.23 1.228.688l7.288 5.669c.21.163.316.245.4.204.084-.04.084-.174.084-.441v-.409c0-.48 0-.72-.102-.928-.101-.208-.291-.355-.67-.65l-7-5.445c-.59-.459-.885-.688-1.228-.688-.343 0-.638.23-1.228.688l-7 5.445c-.379.295-.569.442-.67.65-.102.208-.102.448-.102.928v.409Z"
                  />
                  <path
                    fill="currentColor"
                    d="M11.5 15.5h1A1.5 1.5 0 0 1 14 17v3.5h-4V17a1.5 1.5 0 0 1 1.5-1.5Z"
                  />
                  <path
                    fill="currentColor"
                    d="M17.5 5h-1a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5Z"
                  />
                </svg>
              </NavLink>

              <NavLink
                to="/areas"
                className={({ isActive }) =>
                  `flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 
     hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 
     dark:focus:bg-navy-450  ${
       isActive
         ? "bg-primary/10 text-primary dark:text-accent-light dark:active:bg-navy-450/90 dark:bg-navy-600"
         : ""
     }`
                }
              >
                <svg
                  className="size-7"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.85714 3H4.14286C3.51167 3 3 3.51167 3 4.14286V9.85714C3 10.4883 3.51167 11 4.14286 11H9.85714C10.4883 11 11 10.4883 11 9.85714V4.14286C11 3.51167 10.4883 3 9.85714 3Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.85714 12.8999H4.14286C3.51167 12.8999 3 13.4116 3 14.0428V19.757C3 20.3882 3.51167 20.8999 4.14286 20.8999H9.85714C10.4883 20.8999 11 20.3882 11 19.757V14.0428C11 13.4116 10.4883 12.8999 9.85714 12.8999Z"
                    fill="currentColor"
                    fillOpacity="0.3"
                  />
                  <path
                    d="M19.757 3H14.0428C13.4116 3 12.8999 3.51167 12.8999 4.14286V9.85714C12.8999 10.4883 13.4116 11 14.0428 11H19.757C20.3882 11 20.8999 10.4883 20.8999 9.85714V4.14286C20.8999 3.51167 20.3882 3 19.757 3Z"
                    fill="currentColor"
                    fillOpacity="0.3"
                  />
                  <path
                    d="M19.757 12.8999H14.0428C13.4116 12.8999 12.8999 13.4116 12.8999 14.0428V19.757C12.8999 20.3882 13.4116 20.8999 14.0428 20.8999H19.757C20.3882 20.8999 20.8999 20.3882 20.8999 19.757V14.0428C20.8999 13.4116 20.3882 12.8999 19.757 12.8999Z"
                    fill="currentColor"
                    fillOpacity="0.3"
                  />
                </svg>
              </NavLink>

              <NavLink
                to="/alerts"
                className={({ isActive }) =>
                  `flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 
     hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 
     dark:focus:bg-navy-450 ${
       isActive
         ? "bg-primary/10 text-primary dark:text-accent-light dark:active:bg-navy-450/90 dark:bg-navy-600"
         : ""
     }`
                }
              >
                <svg
                  fill="currentColor"
                  className="size-5"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 123.996 123.996"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5
		C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5
		s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4
		l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"
                    />
                  </g>
                </svg>
              </NavLink>

              <NavLink
                to="/agents"
                className={({ isActive }) =>
                  `flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 
     hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25  dark:hover:bg-navy-450 
     dark:focus:bg-navy-450 ${
       isActive
         ? "bg-primary/10 text-primary dark:text-accent-light dark:active:bg-navy-450/90 dark:bg-navy-600"
         : ""
     }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-7"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </NavLink>
            </div>

            <div className="flex flex-col items-center space-y-3 py-3">
              {/* Settings */}
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  `flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 
     hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 
     dark:focus:bg-navy-450  ${
       isActive
         ? "bg-primary/10 text-primary dark:text-accent-light dark:active:bg-navy-450/90 dark:bg-navy-600"
         : ""
     }`
                }
              >
                <svg
                  className="size-7"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillOpacity="0.3"
                    fill="currentColor"
                    d="M2 12.947v-1.771c0-1.047.85-1.913 1.899-1.913 1.81 0 2.549-1.288 1.64-2.868a1.919 1.919 0 0 1 .699-2.607l1.729-.996c.79-.474 1.81-.192 2.279.603l.11.192c.9 1.58 2.379 1.58 3.288 0l.11-.192c.47-.795 1.49-1.077 2.279-.603l1.73.996a1.92 1.92 0 0 1 .699 2.607c-.91 1.58-.17 2.868 1.639 2.868 1.04 0 1.899.856 1.899 1.912v1.772c0 1.047-.85 1.912-1.9 1.912-1.808 0-2.548 1.288-1.638 2.869.52.915.21 2.083-.7 2.606l-1.729.997c-.79.473-1.81.191-2.279-.604l-.11-.191c-.9-1.58-2.379-1.58-3.288 0l-.11.19c-.47.796-1.49 1.078-2.279.605l-1.73-.997a1.919 1.919 0 0 1-.699-2.606c.91-1.58.17-2.869-1.639-2.869A1.911 1.911 0 0 1 2 12.947Z"
                  />
                  <path
                    fill="currentColor"
                    d="M11.995 15.332c1.794 0 3.248-1.464 3.248-3.27 0-1.807-1.454-3.272-3.248-3.272-1.794 0-3.248 1.465-3.248 3.271 0 1.807 1.454 3.271 3.248 3.271Z"
                  />
                </svg>
              </NavLink>
              <div className="flex">
                <button
                ref={popperRef}
                  x-ref="popperRef"
                  className="avatar size-12"
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                >
                  <img
                    className="rounded-full"
                    src="assets/images/avatar/avatar-12.jpg"
                    alt="avatar"
                  />
                  <span className="absolute right-0 top-0 size-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700" />
                </button>
                <div
                  className={`popper-root fixed ${isProfileOpen ? "show" : ""}`}
                  x-ref="popperRoot"
                  ref={popperRootRef}
                  data-popper-placement="right-end"
                  style={{
                    position: "fixed",
                    inset: "auto auto 0px 0px",
                    margin: "0px",
                    transform: "translate(76px, -12px)",
                  }}
                >
                  <div className="popper-box w-64 rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-600 dark:bg-navy-700">
                    <div className="flex items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
                      <div className="avatar size-14">
                        <img
                          className="rounded-full"
                          src="assets/images/avatar/avatar-12.jpg"
                          alt="avatar"
                        />
                      </div>
                      <div>
                        <Link
                          to="#"
                          className="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light"
                        >
                          {activeUser?.firstName} {activeUser?.lastName}
                        </Link>
                        <p className="text-xs text-slate-400 dark:text-navy-300">
                          {activeUser?.email}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col pt-2 pb-5">
                      {/* <Link
                      to="/settings"
                      className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                    >
                      <div className="flex size-8 items-center justify-center rounded-lg bg-warning text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </div>
                      <div>
                        <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                          Profile
                        </h2>
                        <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                          Your profile setting
                        </div>
                      </div>
                    </Link> */}
                      {/* <Link
                      to="#"
                      className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                    >
                      <div className="flex size-8 items-center justify-center rounded-lg bg-info text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                          />
                        </svg>
                      </div>
                      <div>
                        <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                          Messages
                        </h2>
                        <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                          Your messages and tasks
                        </div>
                      </div>
                    </Link> */}
                      <Link
                        to="/agents"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                      >
                        <div className="flex size-8 items-center justify-center rounded-lg bg-secondary text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            {i18n.t("Team")}
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            {i18n.t("Your team activity")}
                          </div>
                        </div>
                      </Link>
                      {/* <Link
                      to="#"
                      className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                    >
                      <div className="flex size-8 items-center justify-center rounded-lg bg-error text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>
                      <div>
                        <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                          Activity
                        </h2>
                        <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                          Your activity and events
                        </div>
                      </div>
                    </Link> */}
                      <Link
                        to="/settings"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                      >
                        <div className="flex size-8 items-center justify-center rounded-lg bg-success text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            {i18n.t("Settings")}
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            {i18n.t("Webapp settings")}
                          </div>
                        </div>
                      </Link>
                      <div className="mt-3 px-4">
                        <button
                          onClick={() => setLogoutModal(true)}
                          className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                          <span>{i18n.t("Logout")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-panel">
          <div className="flex h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750">
            {/* Sidebar Panel Header */}
            <div className="flex h-18 w-full items-center justify-between pl-4 pr-1">
              <p className="text-base tracking-wider text-slate-800 dark:text-navy-100">
                Menu
              </p>
              <button
                className="btn size-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 "
                onClick={toggleSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </div>

            {/* Sidebar Panel Body */}
            <div
              x-data="{expandedItem:null}"
              className="h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
              x-init="$el._x_simplebar = new SimpleBar($el);"
            >
              <ul className="flex flex-1 flex-col px-4 font-inter">
                <li>
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      `flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
                        isActive
                          ? "font-medium text-primary dark:text-accent-light"
                          : "text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50"
                      }`
                    }
                  >
                    {i18n.t("Dashboard")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/areas"
                    className={({ isActive }) =>
                      `flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
                        isActive
                          ? "font-medium text-primary dark:text-accent-light"
                          : "text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50"
                      }`
                    }
                  >
                    {i18n.t("Areas")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/alerts"
                    className={({ isActive }) =>
                      `flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
                        isActive
                          ? "font-medium text-primary dark:text-accent-light"
                          : "text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50"
                      }`
                    }
                  >
                    {i18n.t("Alerts")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/agents"
                    className={({ isActive }) =>
                      `flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
                        isActive
                          ? "font-medium text-primary dark:text-accent-light"
                          : "text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50"
                      }`
                    }
                  >
                    {i18n.t("Agents")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings"
                    className={({ isActive }) =>
                      `flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
                        isActive
                          ? "font-medium text-primary dark:text-accent-light"
                          : "text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50"
                      }`
                    }
                  >
                    {i18n.t("Settings")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {logoutModal && (
        <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
          <div
            className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
            onClick={() => setLogoutModal(false)}
          ></div>
          <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
            <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
              <div className="mt-4 space-y-4 text-center">
                <p
                  className="text-error"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "larger",
                    fontWeight: "600",
                  }}
                >
                  Attention!
                </p>
                {/* <div
             style={{
               height: "10vh",
               position: "relative",
             }}
           >
             <div
               style={{
                 position: "absolute",
                 top: "50%",
                 left: "50%",
                 transform: "translate(-50%, -50%)",
               }}
               className="text-error"
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 className="size-28"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={1}
                   d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                 />
               </svg>
             </div>
           </div> */}
                <p>Are you sure you want to logout ?</p>

                <div className="space-x-2 text-center">
                  <button
                    onClick={() => setLogoutModal(false)}
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleLogout()}
                    className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-error dark:hover:bg-error-focus dark:focus:bg-error-focus dark:active:bg-error/90"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppSidebar;
