import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DataTable, {
  TableColumn,
  createTheme,
} from "react-data-table-component";
import { createPopper } from "@popperjs/core";
import Popper from "../components/PopperMenu";
import { useAppContext } from "../../layout/context/AppContext";
import { useAppDispatch, useAppSelector } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import alertsThunk from "../../store/thunks/alerts";
import moment from "moment";

function Alerts() {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state: any) => state);
  const { theme } = useAppContext();
  const [freq, setFreq] = useState<string>("yearly");
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [rmvModal, setRmvModal] = useState(false);
  const [detModal, setDetModal] = useState(false);
  const { i18n, t } = useTranslation();
  const [drop, setDrop] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<any>(store.alerts?.allAlerts);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const [label, setLabel] = useState<string>("");
  const [agentId, setAgentId] = useState<string>("");
  const [allUsers, setAllUsers] = useState<any>(store.users?.usersList);
  const [description, setDescription] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [allRegions, setAllRegions] = useState<any>(store.areas?.areasList);
  const [allT, setAllT] = useState(store.alerts?.alertsTypes);
  const [selectedAlert, setSelectedAlert] = useState<any>();

  const filteredAlerts = alerts.filter((alert:any) =>
    alert.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [chartData, setChartData] = useState<{
    series: ApexAxisChartSeries;
    options: ApexOptions;
  }>({
    series: [
      {
        name: "Alerts",
        type: "column",
        color: "#ff5724",
        //#ff5724

        data: [440, 505, 414, 671, 227, 413, 201, 352, 552, 320, 257, 160],
      },
      {
        name: "Interventions",
        type: "column",
        data: [230, 420, 320, 500, 190, 340, 150, 300, 450, 260, 150, 130],
        color: "#10b981",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true, // Only show the download option
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        width: [0, 2],
      },
      plotOptions: {
        bar: {
          columnWidth: "34%", // Adjust bar width (percentage or pixel)
          // borderRadius: 8, // Add border radius for rounded corners
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: [],

      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: -20,
      },
    },
  });


  const settingsColumns: TableColumn<any>[] = [
    {
      name: i18n.t("Name"),
      cell: (row) => <span className="">{row?.label}</span>,
      width: "auto",
      wrap: true,
    },
    {
      name: "Type",
      cell: (row) => <span className="">{row?.typeAlertInfo?.label}</span>,
      width: "auto",
      wrap: true,
    },
    {
      name: i18n.t("Area"),
      cell: (row) =>  <span className="">{allRegions.find((region:any) => region?._id === row?.regionId)?.label}</span>,
      width: "auto",
      wrap: true,
    },
    {
      name: "Date",
      cell: (row) => <span className="">{moment(row?.createdAt).format('D MMMM YYYY')}</span>,
      width: "auto",
      wrap: true,
    },

    {
      name: "Actions",
      width: "auto",
      right: true,
      cell: (row) => (
        <>
          <div className="whitespace-nowrap px-2 py-2 ">
            <Popper
              trigger={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              }
              content={
                <ul>
                  <li>
                    <div
                      onClick={() => {
                        setSelectedAlert(row)
                        setDetModal(true)}}
                      style={{ cursor: "pointer" }}
                      className="flex h-8 items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                    >
                      {i18n.t("Details")}
                    </div>
                  </li>
                  {/* <li>
                    <div
                      onClick={() => setRmvModal(true)}
                      style={{ cursor: "pointer" }}
                      className="flex h-8 items-center px-3 pr-8 text-error font-medium tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                    >
                      Remove
                    </div>
                  </li> */}
                </ul>
              }
            />
          </div>
        </>
      ),
      wrap: true,
    },
  ];
  createTheme(
    "ombre",
    {
      text: {
        primary: "#c2c9d6",
        secondary: "#697a9b",
      },
      background: {
        default: "#26334d",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#697A9B38",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const addAlert = () => {
    dispatch(
      alertsThunk.createAlert({
        label: label,
        typeAlertId:  selectedType ? selectedType : allT[0]?._id,
        userId: agentId ? agentId : allUsers[0]?._id,
        long:"",
        lat:"",
        description:description,
        regionId: selectedRegion ? selectedRegion : allRegions[0]?._id,
        // supRoleId: typeColor,
      })
    )
      .then((success: any) => {
        if (success?.error) {
          toast.error("An error occured");
        } else {
          setRefreshList(true);
          toast.success("Success!");
        }
        setShowModal(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setShowModal(false);
        toast.error("Error");
      });
  };
  const getAlerts = () => {
    setLoader(true);
    dispatch(alertsThunk.getAllAlerts({}))
      .then((success: any) => {
        setAlerts(success?.payload?.data?.list);
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("errr", error);
        setLoader(false);
      });
  };
  const rmvArea = () => {
   
  };
  useEffect(()=>{
    if(refreshList){
      getAlerts();
      setRefreshList(false);
    }
  },[refreshList])
  return (
    <div>
    <ToastContainer />
      <div
        className="grid gap-4 px-[var(--margin-x)] transition-all duration-[.25s] mt-5 sm:gap-5 lg:mt-6 lg:gap-6"
        style={{ width: "90vw" }}
      >
        <div id="pouletto" className="w-full">
          <div className="text-xl font-semibold text-slate-700 dark:text-navy-100">
            {i18n.t("Alerts")}
          </div>
          <div className="mt-4 grid grid-cols-12 gap-4 sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
            <div className="card col-span-12 lg:col-span-12">
              <div className="mt-3 flex flex-col justify-between px-4 sm:flex-row sm:items-center sm:px-5">
                <div className="flex flex-1 items-center justify-between space-x-2 sm:flex-initial">
                  <h2 className="text-sm+ font-medium tracking-wide text-slate-700 dark:text-navy-100">
                   {i18n.t("Overview")}
                  </h2>
                </div>
                {/* <div className="hidden space-x-2 sm:flex">
              <button className="btn h-8 rounded-full text-xs font-medium">
                Monthly
              </button>
              <button className="btn h-8 rounded-full text-xs+ font-medium active ">
                Yearly
              </button>
            </div> */}
              </div>
              <div className="ax-transparent-gridline mt-2 px-2">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={350}
                width={"100%"}
              />
              </div>
            </div>
          </div>
        </div>
        <div className="card" style={{ padding: "2vw" }}>
          <div className="flex items-center justify-between">
            <h2 className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
              {i18n.t("Alerts")} ({alerts?.length})
            </h2>
            <div className="flex">
              <div
                className="flex items-center mr-2"
                x-data="{isInputActive:false}"
              >
                <div className="relative flex">
                  <input
                    placeholder={i18n.t("Search here")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    className="form-input rounded-lg h-10 md:h-8 w-full md:w-56 lg:w-72 rounded-full bg-slate-150 pe-4 pl-9 text-xs+ text-slate-800 ring-primary/50 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:text-navy-100 dark:placeholder-navy-300 dark:ring-accent/50 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                  />
                  <div className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" size-4.5 transition-colors duration-200"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="inline-flex">
                <button
                  onClick={() => setShowModal(true)}
                  className="btn w-full bg-primary h-10 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                  {i18n.t("Add New")}
                </button>
                {showModal && (
                  <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
                    <div
                      className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
                      onClick={() => setShowModal(false)}
                    ></div>
                    <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
                      <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                          {i18n.t("Add Alert")}
                        </h3>
                        <button
                          onClick={() => setShowModal(false)}
                          className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
                        <p>{i18n.t("The new alert you'll add will be displayed to all the concerned people")}.</p>
                        <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>{i18n.t("Name")}:</span>
                            <input
                            defaultValue={label}
                            onChange={(e) => setLabel(e.target.value)}
                              placeholder={i18n.t("Write a short name")}
                              className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            />
                          </label>

                          <label className="block">
                            <span>{i18n.t("Choose agent")}:</span>
                            <select className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent" onChange={(e) =>
                                setAgentId(e.target.value)
                              }
                            >
                              {allUsers?.map((user: any, idx: number) => (
                                <option value={user?._id} key={idx}>
                                  {user?.firstName} {user?.lastName}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label className="block">
                            <span>{i18n.t("Choose area")}:</span>
                            <select className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"defaultValue={selectedRegion}
                              onChange={(e) =>
                                setSelectedRegion(e.target.value)
                              }
                            >
                              {allRegions?.map((region: any, idx: number) => (
                                <option value={region?._id} key={idx}>
                                  {region?.label}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label className="block">
                            <span>{i18n.t("Choose alert type")}:</span>
                            <select className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"defaultValue={selectedRegion}
                              onChange={(e) =>
                                setSelectedType(e.target.value)
                              }
                            >
                              {allT?.map((type: any, idx: number) => (
                                <option value={type?._id} key={idx}>
                                  {type?.label}
                                </option>
                              ))}
                            </select>
                          </label>

                          <label className="block">
                            <span>{i18n.t("Description")}:</span>
                            <textarea
                            defaultValue={description}
                            onChange={(e) => setDescription(e.target.value)}
                              rows={4}
                              placeholder={i18n.t("Describe quickly what's the issue")}
                              className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            ></textarea>
                          </label>

                          {/* <label className="block">
                  <span>Website Address:</span>
                  <input
                    type="text"
                    placeholder="URL Address"
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  />
                </label>

                <label className="inline-flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="form-switch is-outline h-5 w-10 rounded-full border border-slate-400/70 bg-transparent before:rounded-full before:bg-slate-300 checked:border-primary checked:before:bg-primary dark:border-navy-400 dark:before:bg-navy-300 dark:checked:border-accent dark:checked:before:bg-accent"
                  />
                  <span>Public pin</span>
                </label> */}

                          <div className="space-x-2 text-right">
                            <button
                              onClick={() => setShowModal(false)}
                              className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            >
                              {i18n.t("Cancel")}
                            </button>
                            <button
                              onClick={() => addAlert()}
                              className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                              {i18n.t("Add")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <DataTable
              columns={settingsColumns}
              data={filteredAlerts}
              pagination
              // style={{overflowX:'hidden'}}
              theme={theme === "dark" ? "ombre" : "light"}
              progressComponent={
                <>
                  <div className="app-preloader fixed z-50 grid h-full w-full place-content-center bg-slate-50 dark:bg-navy-900">
                    <div className="app-preloader-inner relative inline-block size-48"></div>
                  </div>
                </>
              }
              responsive
              noDataComponent={<>{i18n.t("No data")}.</>}
            />
            {rmvModal && (
              <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
                <div
                  className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
                  onClick={() => setShowModal(false)}
                ></div>
                <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
                  <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4 text-center">
                    <p className="text-error" style={{textTransform:'uppercase', fontSize:'larger', fontWeight:'600'}}>Attention!</p>
                      {/* <div
                        style={{
                          height: "10vh",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          className="text-error"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-28"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        </div>
                      </div> */}
                      <p>Are you sure you want to remove this area ?</p>

                      <div className="space-x-2 text-center">
                        <button
                          onClick={() => setRmvModal(false)}
                          className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => rmvArea()}
                          className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-error dark:hover:bg-error-focus dark:focus:bg-error-focus dark:active:bg-error/90"
                        >
                          Send Request
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
             {detModal && (
               <div className="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5">
               <div
                 className="absolute inset-0 bg-slate-900/60 transition-opacity duration-300"
                 onClick={() => setDetModal(false)}
               ></div>
               <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white transition-all duration-300 dark:bg-navy-700">
                 <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                   <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                    {i18n.t("Alert details")}
                   </h3>
                   <button
                     onClick={() => setDetModal(false)}
                     className="btn -mr-1.5 size-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       className="size-4.5"
                       fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor"
                       strokeWidth={2}
                     >
                       <path
                         strokeLinecap="round"
                         strokeLinejoin="round"
                         d="M6 18L18 6M6 6l12 12"
                       />
                     </svg>
                   </button>
                 </div>

                 <div className="flex flex-col overflow-y-auto px-4 py-4 sm:px-5">
                 <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <label className="block">
                          <span>{i18n.t("Name")}: </span>
                          <span>{selectedAlert?.label}</span>
                        </label>
                        <label className="block">
                          <span>{i18n.t("Alert Type")}: </span>
                          <span className={`tag rounded-full border border-warning/30 bg-warning/10 text-warning`}>{selectedAlert?.typeAlertInfo?.label}</span>
                        </label>

                        <label className="block">
                          <span>{i18n.t("Area")}: </span>
                          <span>{allRegions.find((region:any) => region?._id === selectedAlert?.regionId)?.label}</span>
                        </label>
                        <label className="block">
                          <span>Date: </span>
                          <span>{moment(selectedAlert?.createdAt).format('D MMMM YYYY HH:MM:ss')}</span>
                        </label>
                        <label className="block">
                          <span>{i18n.t("Agent Name")}: </span>
                          <span>{selectedAlert?.userInfo?.firstName} {selectedAlert?.userInfo?.lastName}</span>
                        </label>
                        <label className="block">
                          <span>{i18n.t("Agent Mail")}: </span>
                          <span>{selectedAlert?.userInfo?.email}</span>
                        </label>
                      </div>
                   <div className="mt-4 space-y-4">
                     
                     {/* <div className="space-x-2 text-right">
                       <button
                         onClick={() => setDetModal(false)}
                         className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                       >
                         Cancel
                       </button>
                       
                     </div> */}
                   </div>
                 </div>
               </div>
             </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alerts;
