import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import usersThunk from '../thunks/users'; 

interface UsersState {
    usersList: any;
    loading: boolean;
    error: any;
}
const initialState: UsersState = {
    usersList: [],
    loading: false,
    error: false,
};
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(usersThunk.getAllUsers.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(usersThunk.getAllUsers.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.usersList = action.payload.data.userListData; 
        })
        .addCase(usersThunk.getAllUsers.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Failed to fetch users';
        });
    }
  });
  
  export const selectUsers = (state: any) => state.users.usersList;
  export const usersReducer = usersSlice.reducer;