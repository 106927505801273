import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../helpers/axios.helper';

interface LoginData {
  phoneNumber: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    activate: boolean;
    phoNumber: number;
    // Add other user properties as needed
  };
}

interface LoginError {
  message: string; // Define the structure of the error response
}

const login = createAsyncThunk<LoginResponse, LoginData, { rejectValue: LoginError }>(
  'login', // It's a good practice to include the namespace here
  async (data, { rejectWithValue }) => {
    try {
      const response = await post(`auth/login`, data);
      return response.data; // assuming the response contains token and user data
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data as LoginError); // Cast the error to LoginError
      }
      return rejectWithValue({ message: error.message }); // Ensure it matches the expected type
    }
  }
);

const loginThunk = {
  login,
};
export default loginThunk;
