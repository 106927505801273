import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      // Fallback UI for the error
      return (
        <div className="card p-4 mt-5 flex h-screen">
          <div className="flex items-center justify-between">
            <div className="font-inter font-medium text-error tracking-wide transition-colors hover:text-error-800 focus:text-error-800 dark:hover:text-error-100 dark:focus:text-error-100">
              {t("Something went wrong")}.
            </div>

            <i className="fa-brands fa-error"></i>
          </div>
          <p className="mt-3 text-xs+">
            {t("Please try refreshing the page, or contact support if the issue persists")}.
          </p>
          <div className="mt-3 text-xs">
            <button
              onClick={() => window.location.reload()}
              className="btn bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            >
              {t("Refresh Page")}
            </button>
          </div>
        </div>
      );
    }

    return this.props.children; // Render children if no error
  }
}

export default withTranslation()(ErrorBoundary);
