import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store'; // Adjust path as necessary
import loginThunk from '../../../../store/thunks/login';
import Spinner from "../../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";


const LoginPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch(); // Use the typed dispatch
  const navigate = useNavigate();

  // Local state for username and password
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault(); // Prevent default form submission behavior
    const loginData = { phoneNumber, password };
    dispatch(loginThunk.login(loginData))
    .then((success:any) => {
      setLoading(false)
      if(success?.error){
        toast.error(i18n.t("An error occured"));
        }else{
        navigate('/dashboard')
      }
    })
    .catch((error:any)=>{
      setLoading(false)
      console.log('error', error)
      toast.error(error?.message)
    }); // Dispatch the thunk
  };

   
  return (
    <>
    <ToastContainer />
    <div className="absolute p-4" style={{ zIndex: 10 }}>
      <select
        className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-4 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
        style={{ paddingRight: "2rem" }}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        <option value="en">🇬🇧 English</option>
        <option value="fr">🇫🇷 Français</option>
      </select>
    </div>

    <main
      className="grid w-full grow grid-cols-1 place-items-center"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div className="w-full max-w-[26rem] p-4 sm:px-5">
        <div className="text-center">
          <img
            className="mx-auto size-16"
            src="assets/images/app-logo.svg"
            alt="logo"
          />
          <div className="mt-4">
            <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
              {i18n.t("Welcome Back")}
            </h2>
            <p className="text-slate-400 dark:text-navy-300">
              {i18n.t("Please sign in to continue")}
            </p>
          </div>
        </div>

        <form onSubmit={handleLogin} className="card mt-5 rounded-lg p-5 lg:p-7">
          <label className="block">
            <span>{i18n.t("Username")}:</span>
            <span className="relative mt-1.5 flex">
              <input
                className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                placeholder={i18n.t("Enter Username")}
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </span>
          </label>

          <label className="mt-4 block">
            <span>{i18n.t("Password")}:</span>
            <span className="relative mt-1.5 flex">
              <input
                className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                placeholder={i18n.t("Enter Password")}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </span>
          </label>

          <button
            className="btn mt-5 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "
            type="submit"
            disabled={loading}
          >
          {loading ? <Spinner size={5}/>: i18n.t("Sign In")}
          </button>
        </form>

        <div className="mt-8 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <Link to={'#'}>Privacy Notice </Link>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500" />
          <Link to={'#'}>Term of service </Link>
        </div>
      </div>
    </main>
  </>
  );
};

export default LoginPage;
