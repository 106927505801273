import React, { useState, useRef, useEffect } from 'react';
import { createPopper } from '@popperjs/core';

interface PopperProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  placement?: 'bottom-end' | 'top' | 'left' | 'right'; // Allow different popper positions
  offset?: [number, number]; // Allow offset configuration
}

const Popper: React.FC<PopperProps> = ({ trigger, content, placement = 'bottom-end', offset = [0, 4] }) => {
  const [isShowPopper, setIsShowPopper] = useState(false);
  const popperRef = useRef<HTMLButtonElement>(null);
  const popperRoot = useRef<HTMLDivElement>(null);
  const isClosingRef = useRef(false); // Prevents race conditions

  // Close popper if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRoot.current &&
        !popperRoot.current.contains(event.target as Node) &&
        !popperRef.current?.contains(event.target as Node)
      ) {
        setIsShowPopper(false);
      }
    };

    if (isShowPopper) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShowPopper]);

  // Set up popper.js to handle positioning
  useEffect(() => {
    if (popperRef.current && popperRoot.current) {
      createPopper(popperRef.current, popperRoot.current, {
        placement,
        modifiers: [{ name: 'offset', options: { offset } }],
      });
    }
  }, [placement, offset]);

  // Handle popper toggle with debounce to prevent flashing
  const handlePopperToggle = () => {
    if (isClosingRef.current) {
      return; // Prevent race condition between closing and opening
    }

    if (isShowPopper) {
      isClosingRef.current = true;
      setTimeout(() => {
        setIsShowPopper(false);
        isClosingRef.current = false;
      }, 150); // Delay the close to prevent flashing
    } else {
      setIsShowPopper(true);
    }
  };

  // Close popper when content is clicked
  const handleContentClick = () => {
    setIsShowPopper(false);
  };

  return (
    <div className="">
      {/* Trigger element */}
      <button
        ref={popperRef}
        onClick={handlePopperToggle}
        className="btn size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
      >
        {trigger}
      </button>

      {/* Popper element */}
      <div
        ref={popperRoot}
        className={`popper-root ${isShowPopper ? 'show' : ''}`}
        style={{ display: isShowPopper ? 'block' : 'none' }}
        onClick={handleContentClick} // Close popper on content click
      >
        <div className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Popper;
